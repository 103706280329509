.employee-details-registration {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90.5vh;
    min-width: 850px;
}

.employee-container {
    max-width: 760px;
    width: 100%;
    min-height: auto;
    max-height: 100%;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: #98cfff;
}

.employee-container .title {
    text-align: center;
    padding-bottom: 10px;
    
}

.employee-form .employee-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.employee-details .input-container {
    margin: 15px 0 12px 0;
}

.employee-details .input-container input {
    height: 40px;
    width: 230px;
    padding-left: 10px;
    color: black;
    font-size: 13px;
}

.employee-details .input-container select {
    height: 40px;
    width: 230px;
    display: block;
    padding-left: 10px;
    color: black;
    font-size: 13px;
}

.employee-details .input-container .profile-label{
   margin-top: -20px;
   margin-bottom: 15px;
   margin-left: 28px;
}

.employee-details .input-container textarea {
    color: black;
    font-size: 13px;
}

.employee-details .input-container #Address {
    width: 400px;
    height: 60px;
    padding: 10px;
}


.employee-details .input-container #Age {
    width: 100px;
}

.employee-details .input-container #Name {
    width: 260px;
}

.employee-details .input-container #Email {
    width: 290px;
}

.employee-details .input-container #Gender {
    width: 15px;
}

.employee-details .input-container label {
    display: block;
    padding-bottom: 5px;

}


.employee-details button {
    width: 160px;
    height: 50px;
    margin-top: 32px;
    background-color: #c2def6;
    color: black;
}

.employee-details button:hover {
    background-color: #5fa7e6;
    color: white;
    }

.employee-details .radio-btn input[type="radio"] {
    margin-left: 5px;
    margin-right: 5px;
}

.employee-details .radio-btn-container label {
    margin-right: 15px;
}

.employee-details .radio-options label {
   font-size: 14px;
}

.view-employees-table-btn {
    margin-top: -650px;
    margin-right: 30px;
    float: right;

}

.view-employees-table-btn button {
    width: 110px;
    height: 50px;
    background-color: #98cfff;
    border-radius: 5px;
    color: black;
}

.view-employees-table-btn button:hover {
    background-color: #7bb6ea;
    color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.profile-pic-container{
    height: 100px;
    width:100px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgb(161, 160, 160);
}

.profile-pic-container #Profile{
    display: none;
}

.profile-pic-container #profile-img{
    height: 100%;
    width: 100%;
   
}

.profile-pic-container #pic-upload-btn{
    text-align: center;
    margin-top: -28px;
    line-height: 30px;
    background-color: rgb(0, 0, 0, 0.25);
    color: white;
    cursor: pointer;
    position: relative;
    display: none;
 
   
}

.profile-pic-container:hover #pic-upload-btn {
    display: block;
}

.profile-pic-container{
    margin-top: -20px;
   
}

.wrap {
    flex-basis: 100%;
}

.employee-container .Onsite-Project{
    margin-top: 30px;
}

.employee-container .wrap2 {
    flex-basis: 20%;
}

.onsite-duration{
    display: flex;
    justify-content: space-between;
    flex-grow: 0.2;
}

.leave-info {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.employee-details-registration .input-container p {
    font-size: 13px;
    color: blue;
    margin-left: 1px;
    margin-bottom: -20px;
}

.employee-details-registration .radio-btn-container p {
    font-size: 13px;
    color: blue;
    margin-left: 1px;
    margin-bottom: -20px;
}

.employee-details-registration .input-container .inputError {
    border: 2px solid #FF8BA0;
}

.employee-container .Loader{
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
}

.update-single-data .Loader {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 40%;
    transform: translate(40%, -50%);
}