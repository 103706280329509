.meeting-registration {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 91.3vh;
    margin-top: -3px;
    min-width: 850px
}

.meeting-registration-container {
    max-width: 780px;
    width: 100%;
    height: auto;
    max-height: 100%;
    padding: 16px 30px;
    border-radius: 5px;
    background-color: #98cfff;
}

.meeting-registration-container .title {
    text-align: center;
    margin-top: -12px;
    font-size: 15px;
}

.meeting-registration .meeting-form .meeting-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -2px;
}

.meeting-details .input-container {
    margin: 10px 0 12px 0;
}

.meeting-details .input-container input {
    height: 38px;
    width: 230px;
    padding-left: 10px;
    color: black;
    font-size: 13px;
}

.meeting-details .time-input-container{
    margin: 10px 0 12px 0;
}

.meeting-details .time-input-container input{
    height: 38px;
    width: 180px;
    padding-left: 10px;
    color: black;
    font-size: 13px;

}

.meeting-details .time-input-container .rdtPicker {
    height: 100px;
    min-width: 180px;
}

.meeting-details .time-input-container .rdtTime {
    font-size: 12px;
    justify-content: center;
}

.meeting-details .time-input-container .rdtCounters {
    font-size: 11px;
}

.meeting-details .time-input-container .inputError input{
    border: 2px solid #FF8BA0;
}

.meeting-details .time-input-container p {
    font-size: 13px;
    color: blue;
    margin-left: 1px;
    margin-top: 5px;
    margin-bottom: -12px;
}

.meeting-details .input-container textarea {
    color: black;
    font-size: 13px;
}


.meeting-details .input-container #meeting_date {
    width: 190px;
}

.meeting-details .input-container #visitor_address {
    width: 400px;
    height: 50px;
    padding: 10px;
    margin-bottom: -5px;
}

.meeting-details .input-container #meeting_purpose {
    width: 350px;
    height: 45px;
    padding: 10px;
    margin-bottom: -5px;
}

.meeting-details .input-container #meeting_duration {
    width: 150px;

}


.meeting-details .time-input-container .clock-icon {

   margin-top: -28px;
   margin-left: 170px;
   margin-right: 5px;
   position: relative;
}


.meeting-details .input-container label {
    display: block;
    padding-bottom: 3px;
   
}

.meeting-details .time-input-container label {
    display: block;
    padding-bottom: 3px;

}


.meeting-details .input-container button {
    width: 160px;
    height: 50px;
    margin-top: 30px;
    background-color: #c2def6;
    color: black;
}

.meeting-details button:hover {
    background-color: #5fa7e6;
    color: white;
}

.meeting-details .input-container #autofill-btn {
    width: 100px;
    height: 38px;
    background-color: #c2def6;
    color: black;
    margin-top: 25px;
}


.view-meetings-table-btn {
    margin-top: -650px;
    margin-right: 30px;
    float: right;

}

.view-meetings-table-btn button {
    width: 110px;
    height: 50px;
    background-color: #98cfff;
    border-radius: 5px;
    color: black;
}

.view-meetings-table-btn button:hover {
    background-color: #7bb6ea;
    color: white;
}

.meeting-registration-container .wrap {
    flex-basis: 100%;
}

.meeting-registration-container .wrap2 {
    flex-basis: 65%;
}

.meeting-registration-container .wrap3 {
    flex-basis: 28%;
}

.meeting-registration-container .wrap4 {
    flex-basis: 40%;
}

.meeting-registration .input-container p {
    font-size: 13px;
    color: blue;
    margin-left: 1px;
    margin-bottom: -20px;
}

.meeting-registration .radio-btn-container p {
    font-size: 13px;
    color: blue;
}

.meeting-registration .input-container .inputError {
    border: 2px solid #FF8BA0;
}

