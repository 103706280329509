.display-table {
    margin-top: -20px;
    height: 94vh;
    z-index: 0;
}

.table-data {
    text-align: center;
    justify-content: space-around;
    min-width: 670px;
    overflow-y: scroll;
    max-height: 590px;
}

#loading-text {
    margin-top: 20px;
    text-align: center;
}

.table-data table {
    width: 100%;
}

.table-data th {
    background-color: #98cfff;
    height: 50px;
    position: sticky;
    top: 0px;
}

.table-data tr:nth-child(even) {
    background-color: #bee8ff;
    height: 40px;
}

.table-data tr:nth-child(odd) {
    background-color: #d0f2ff;
    height: 40px;
}


.table-container td .icons-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-container td .icons-container .icons {
    padding-left: 12px;
    justify-content: space-between;
}

.table-container .icons-container .icons:hover {
    color: #1777e4;
}

.table-data mark {
    background-color: #00dfff;
}

.display-table .reports-count{
    display:flex;
    justify-content: space-between;
    margin-top: -10px;
    margin-bottom: 8px;
    margin-left: 15px;
    margin-right: 35px;
}

.display-single-data {
    background-color: #e7f5fe;
    width: auto;
    height: auto;
    min-width: 400px;
    padding: 30px 40px;
    position: absolute;
    top: 50%;
    left: 58%;
    transform: translate(-58%, -45%);

}

.single-data {
    display: flex;
    margin-top: 10px;
}

.single-data p {
    margin-left: 10px;
}


.single-data-container .profile-pic-container {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgb(103, 102, 102);
    margin-top: 15px;
    margin-right: 20px;
    float: left;
}


.single-data-container .profile-pic-container img {
    height: 100%;
    width: 100%;

}

.single-data .input-container input {
    width: 180px;
    margin-left: 0px;
    margin-bottom: 5px;
}

.single-data-container h2 {
    padding-bottom: 10px;
}

.table-header {
    display: flex;
    align-items: center;
}

.table-search-container {
    margin: 20px;
}

.table-search-container input {
    height: 35px;
    width: 280px;
    background-color: #dae5f3;
    padding-left: 10px;
    color: black;
}

.display-table .inputDate-container input{
    height: 35px;
    width: 150px;
    background-color: #dae5f3;
    padding-left: 10px;
    color: #0f61bf;
    font-size: 16px;
    margin-right: -40px;
}

.display-table .inputDate-container .date{
    color: #0f61bf;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    margin-right: -40px;
}

.display-table .inputDate-container .date2{
    color: #0f61bf;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    margin-right: -150px;
}

.table-header .search-icon {
    height: 25px;
    background-color: #98cfff;
    padding: 5px;
    margin-left: -20px;
    color: #072e76;

}

.table-title {
    flex-grow: 0.90;
    text-align: center;
    color: #1777e4;
    font-size: 19px;
    margin-left: -90px;
    margin-right: 90px;
}

.table-title2 {
    flex-grow: 0.80;
    text-align: center;
    color: #1777e4;
    font-size: 19px;
    margin-right: 260px;
    margin-left: 80px;
}

.display-preregister-table-title {
    flex-grow: 0.90;
    text-align: center;
    color: #1777e4;
    font-size: 19px;
    margin-left: 10px;
    margin-right: 30px;
}


.single-data-close-icon {
    float: right;
    margin-top: -10px;
}

.single-data-close-icon:hover {
    color: red;
}

.meeting-data-table mark {
    background-color: #00dfff;
}

.display-table .table-data-not-found {
    display: flex;
    justify-content: center;
    padding-left: -40px;
    font-size: large;
    color: blue;
    padding-top: 30px;
}

.update-single-data {
    background-color: #e7f5fe;
    width: auto;
    height: auto;
    padding: 30px 40px;
    position: absolute;
    top: 52%;
    left: 60%;
    transform: translate(-60%, -50%);
}


.update-single-data .profile-pic-container {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgb(103, 102, 102);
    margin-top: 5px;
    margin-right: 20px;
    float: left;
}

.update-single-data .title {
    margin-bottom: 20px;
    margin-top: -5px;
}


.update-single-data input {
    width: 250px;
    height: 30px;
    margin-left: 20px;
    padding-left: 10px;
    background-color: #d8e2e7;
    color: black;
    font-size: 14px;
}

.update-single-data .inputError{
    border: 2px solid #eaa5b2;
}

.update-single-data  p {
    font-size: 13px;
    color: blue;
   
    margin-left: 100px;
}


.update-single-data label {
    font-weight: 650;
    color: black;
}

.update-single-data select {
    width: 180px;
    height: 30px;
    margin-left: 0px;
    margin-right: 30px;
    padding-left: 10px;
    background-color: #d8e2e7;
    color: black;
    font-size: 14px;
}

.update-single-data .radio-btn input {
    height: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 30px;
}


.update-single-data .input-container input {
    width: 180px;
    margin-right: 20px;
    margin-bottom: 5px;
}

.update-single-data textarea {
    width: 350px;
    height: 50px;
    margin-left: 20px;
    padding-left: 10px;
    background-color: #d8e2e7;
    color: black;
    font-size: 14px;
}


.update-single-data button {
    margin-top: 20px;
    height: 35px;
    width: 70px;
    padding: 10px;
    color: black;
    background-color: #d8e2e7;
}

.update-single-data button:hover {
    background-color: rgb(177, 176, 176);
    color: black;
}

.update-single-data  .rdtPicker {
    height: 100px;
    min-width: 180px;
}

.update-single-data  .rdtTime {
    font-size: 12px;
    justify-content: center;
}

.update-single-data .rdtCounters {
    font-size: 11px;
}

.redirect-table-btns {
    display: flex;
    flex-direction: column;
    float: right;
    margin-right: 20px;
    margin-top: -640px;

}

.redirect-table-btns .view-table-btn button {
    width: 110px;
    height: 50px;
    background-color: #98cfff;
    border-radius: 5px;
    color: black;
    margin-bottom: 20px;
}

.redirect-table-btns .view-table-btn button:hover {
    background-color: #7bb6ea;
    color: white;
}

.display-table .SelectDateOption{
    margin-right: 20px;
}

.display-table .SelectDateOption input {
    margin-right: 5px;
}

.display-table .SelectDateOption label {
    font-size: 14.5px;
}