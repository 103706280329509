.checkInOut {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88vh;
    padding: 10px;
    min-width: 500px;
}

.checkInOut .checkInOut-container {
    max-width: 400px;
    width: 100%;
    height: auto;
    padding: 30px;
    border-radius: 5px;
    background-color: #98cfff;
}

.checkInOut .checkInOut-container .title {
    text-align: center;
    padding-bottom: 10px;
}

.checkInOut .checkInOut-form .checkInOut-details {
    display: flex;
    flex-wrap: wrap;
    margin-left: 30px;
}

.checkInOut .checkInOut-details .input-container {
    margin: 20px 0px 12px 0px;
}

.checkInOut .checkInOut-details .input-container input {
    height: 40px;
    width: 300px;
    display: inline-block;
    padding-left: 10px;
    color: black;
    font-size: 13px;
}


.checkInOut .checkInOut-details .input-container label {
    display: block;
    padding-bottom: 5px;
    padding-left: 2px;
}

.checkInOut .checkInOut-details button {
    width: 110px;
    height: 45px;
    margin-left: 100%;
    margin-right: auto;
    margin-top: 20px;
    background-color: #c2def6;
    color: black;
}

.checkInOut .checkInOut-details button:hover {
    background-color: #5fa7e6;
    color: white;
}

.checkInOut .input-container {
    position: relative;
    margin: 20px 0 12px 0;
}

.checkInOut .input-container p {
    font-size: 13px;
    color: blue;
    margin-bottom: -20px;
    margin-left: 1px;
}

.checkInOut .input-container .inputError {
    border: 2px solid #FF8BA0;
}

.checkInOut .print-icon {
   padding: 7px;
   background-color: #c2def6;
   position:absolute;
   display: block;
   left:100%;
   top: 82%;
   transform: translateY(-70%);
}

.checkInOut .print-icon:hover{
    background-color: #5fa7e6;
}

.checkInOut .sent-otp-btn {
    padding: 5px;
    width:60px;
    height:30px;
    background-color: #c2def6;
    position: absolute;
    left: 100%;
    top: 82%;
    transform: translateY(-70%);
}

.checkInOut .sent-otp-btn p{
    margin-top: 5px;
    color: black;
    display: block;
}

.checkInOut .input-container .sent-otp-btn:hover {
    background-color: #5fa7e6;
}

.checkInOut .input-container .sent-otp-btn:hover p{
    color: white;
}

.checkInOut #Admin_Email{
    width: 260px;
}

.checkInOut #OTP {
    width: 260px;
}

.checkInOut #otp-submit-btn{
    margin-left: 100%;
    margin-right: auto;
}

.checkInOut .otp-timeout {
    padding: 5px;
    width: 60px;
    height: 30px;
    position: absolute;
    display: block;
    left: 99%;
    top: 41%;
    transform: translateY(-70%);
}

.checkInOut #otp-timeout {
   padding: 10px;
   margin-top: 35px;
   display: block;
}

.checkInOut small{
    font-size: 12px;
    color: rgb(145, 143, 143);
    margin-left: 85px;
   
}