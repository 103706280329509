.visitor-details-registration{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88vh;
    padding: 10px;
}

.visitor-container{
    max-width: 500px;
    width: 100%;
    height: auto;
    padding: 25px 30px;
    border-radius: 5px;
    background-color: #98cfff;
}

.visitor-container .title{
    text-align: center;
    padding-bottom: 10px;
}

.visitor-form .visitor-details{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.visitor-details .input-container{
    margin: 10px 0 12px 0;

}

.visitor-details .input-container input{
    height: 40px;
    width: 230px;
    display: block;
    padding-left: 10px;
    color: black;
    font-size: 13px;
}

.visitor-details .input-container textarea{
    color: black;
    font-size: 13px;
}

.visitor-details .input-container #Address{
    width: 480px;
    height: 60px;
    padding: 10px;
}

.visitor-details #Address input{
    padding-top: 10px;
}

.visitor-details .input-container #Age {
    width: 100px;
}

.visitor-details .input-container #Email {
    width: 358px;
}

.visitor-details .input-container #Gender {
    width: 15px;
}

.visitor-details .input-container label {
   display: block;
   padding-bottom: 5px;
   padding-left: 2px;
}

.visitor-details button{
    width: 100px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    background-color: #c2def6;
    color: black;
}

.visitor-details button:hover{
    background-color: #5fa7e6;
    color: white;
}

.visitor-details .radio-btn input[type="radio"]{
    margin-left: 20px;
    margin-right: 3px;
}

.visitor-details .radio-btn-container{
    margin-top: 20px;
    margin-bottom: 15px;
}

.visitor-details .radio-btn-container label {
    margin-right: 15px;
}

.visitor-form .title h2{
    text-align: center;
    padding-bottom: 10px;
}

.view-visitors-table-btn{
    margin-top: -650px;
    margin-right: 30px;
    float:right;
    
}

.view-visitors-table-btn button{
    width: 110px;
    height: 50px;
    background-color: #98cfff;
    border-radius: 5px;
    color: black;
}

.view-visitors-table-btn button:hover {
    background-color: #7bb6ea;
    color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.visitor-details-registration .input-container p {
    font-size: 13px;
    color: blue;
    margin-bottom: -10px;
    margin-left: 1px;
    margin-bottom: -20px;
}

.visitor-details-registration .radio-btn-container p {
    font-size: 13px;
    color: blue;
    margin-left: 1px;
    margin-bottom: -20px;
}

.visitor-details-registration .input-container .inputError {
    border: 2px solid #FF8BA0;
}

