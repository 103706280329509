.App {
  margin: 0;
  padding: 0;
  border: 0;
}

.server-loading{
  position: absolute;
  top:45%;
  left:50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.server-connection-warning {
  display: flex;
  color: rgb(190, 189, 189);
  font-size: 13px;
  margin-top: 30px;

}

